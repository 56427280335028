@import "styles/base";

$block-name: "b-form-constructor";

.#{$block-name} {
	padding-bottom: 150px;

	&__title {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.02em;
		margin-bottom: 20px !important;

		@include breakpoint("tablet") {
			margin-bottom: 25px !important;
		}

		@include breakpoint("middle") {
			margin-bottom: 30px !important;
		}

		@include breakpoint("desktop") {
			margin-bottom: 40px !important;
		}
	}

	&__btn {
		background-color: #686c6e;
		color: #fff;
		text-transform: uppercase;
		font-family: Arial;
		font-size: 14px;
		line-height: 16.8px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 40px;
		cursor: pointer;
		transition: background-color 0.2s linear;
		margin-top: 25px;

		&:hover {
			background-color: #0098c3;
		}

		@include breakpoint("tablet") {
			display: inline-block;
			margin-top: 40px;
		}
	}

	.b-default,
	.b-email,
	.b-phone,
	.b-select,
	.b-textarea {
		position: relative;
		margin-bottom: 15px;
		background-color: $color-gray-input;

		@include breakpoint("tablet") {
			margin-bottom: 20px;
		}
	}

	.b-checkbox {
		margin-bottom: 10px;
	}

	.b-file {
		position: relative;
		margin-bottom: 30px;

		@include breakpoint("tablet") {
			margin-bottom: 40px;
		}
	}

	.choices__list--single { /* stylelint-disable-line */
		background-color: $color-gray-input;
	}

	.disabled-btn {
		pointer-events: none;
	}
}
