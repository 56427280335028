// Пользовательские переменные

// Цвета, используемые на сайте (по возможности желательно придерживаться этого формата)
$color-white: #fefefe;
$color-black: #0a0a0a;
$color-gray: #cacaca;
$color-gray-dark: #a6a7a9;
$color-gray-light: #d9dfe2;
$color-red: #e53d3d;
$color-aquamarine: #0098c3;
$color-graphite: #293132;

// UI-Kit colors
$color-gray-input: #f6f7f7;
$color-uikit-graphite: #293132;
$color-uikit-aquamarine: #0098c3;
$color-uikit-white: #fefefe;
$color-uikit-grey: #a6a7a9;
$color-uikit-orange: #e95025;

$color-uikit-light-blue: #b7e3f0;
$color-uikit-light-grey: #f6f7f7;
$color-uikit-dark-grey: #686c6e;
$color-uikit-red: #e53d3d;

// Основные параметры body
$body-background: $color-white;
$body-text-color: $color-black;
$body-font-family: arial, sans-serif;

$site-breakpoints: (
	mobile: 360px,
	tablet: 768px,
	middle: 1024px,
	desktop: 1440px,
	full: 1920px,
);

// отступ контента на брекпоинтах (через .l-content-container)
// работают через media-запрос min-width
$content-offset-over-min: 15px;
$content-offsets: (
	mobile: 15px,
	tablet: 50px,
	middle: 50px,
	desktop: 100px,
);

// отступ для grid-container на разных брекпоинтах
// работают через media-запрос min-width
$gutter-size-over-min: 15px;
$gutter-size: (
	mobile: 15px,
	tablet: 20px,
	middle: 30px,
	desktop: 30px,
);

// Отступы
$indents: (
	"green": (
		"color": #a5e6c3,
		"sizes": (
			"mobile": 60px,
			"tablet": 80px,
			"desktop": 130px,
		),
	),
	"red": (
		"color": red,
		"sizes": (
			"mobile": 50px,
			"tablet": 60px,
			"desktop": 70px,
		),
	),
);
