@import "styles/base";

$block-name: "b-grid";

.#{$block-name} {
	@include grid-container12;

	display: none;
	position: fixed;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	pointer-events: none;

	&.is-active {
		display: grid;
	}

	@include breakpoint("tablet") {
		width: calc(100%);
	}

	&__line {
		display: none;
		background-color: red;
		opacity: 0.1;
		height: 100%;

		@include breakpoint("mobile") {
			&.mobile {
				display: block;
			}
		}

		@include breakpoint("tablet") {
			&.tablet {
				display: block;
			}
		}

		@include breakpoint("desktop") {
			display: block;
		}
	}
}
