@import "styles/base";

$block-name: "b-file";

.#{$block-name} {
	// TODO: нужно убрать font-size
	&__input-file {
		display: block;
	}

	&__input {
		cursor: pointer;
		background: none;
		border: none;
		border-radius: none;
		width: 100%;
		line-height: 130%;
		padding: 20px 20px 15px;
		padding-left: 0;
		font-size: 13px;
		font-weight: 400;
		color: $color-graphite;
		box-sizing: border-box;
		display: flex;
		position: relative;
		flex-wrap: wrap;
		row-gap: 30px;
		column-gap: 5px;

		@include breakpoint("tablet") {
			font-size: 14px;
		}

		@include breakpoint("middle") {
			font-size: 16px;
		}
	}

	&__item {
		padding: 10px 35px 10px 20px;
		border: 2px solid $color-graphite;
		border-radius: 50px;
		margin-top: -10px;
		margin-bottom: -10px;
		position: relative;

		&:hover {
			&::after {
				transform: rotate(90deg);
			}
		}

		&::after {
			content: "";
			width: 15px;
			height: 20px;
			position: absolute;
			right: 10px;
			background: $color-graphite;
			mask-image: url("img/close.svg");
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: contain;
			cursor: pointer;
			transition: all 0.4s;
		}
	}

	&.error {
		input {
			padding: 30px 20px 10px;
		}

		.#{$block-name} {
			&__input,
			&__message {
				color: $color-red;
			}

			&__input {
				border-bottom: 2px solid $color-red;

				&::after {
					background: $color-red;
				}

				&::before {
					content: "";
					width: 25px;
					height: 23px;
					position: absolute;
					right: 35px;
					background: $color-red;
					mask-image: url("img/restart_icon.svg");
					mask-repeat: no-repeat;
					mask-position: center;
					mask-size: contain;
					cursor: pointer;
				}
			}

			&__error-message {
				display: block;
				z-index: 1;
			}
		}
	}

	&__error-message {
		display: none;
		color: $color-red;
		position: absolute;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		left: 20px;
		top: 8px;
	}

	&__message {
		align-self: stretch;
		color: $color-uikit-dark-grey;
		border-top: 2px solid $color-uikit-graphite;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		padding-top: 10px;
	}

	input[type="file"] {
		position: absolute;
		z-index: -1;
		opacity: 0;
		display: block;
		width: 0;
		height: 0;
	}

	&__input-file input[type="file"]:disabled + &__input-file-btn {
		background-color: #eee;
	}

	.filepond {
		&--credits {
			display: none;
		}

		&--root {
			margin: 0;
			padding: 0;
			padding-top: 20px;
			padding-bottom: 15px;
		}

		&--panel-root {
			background-color: transparent;
		}

		&--drop-label {
			justify-content: flex-start;
			min-height: 60px !important;

			&::after {
				content: "";
				width: 25px;
				height: 25px;
				position: absolute;
				right: 0;
				background: $color-graphite;
				mask-image: url("img/icon_attach.svg");
				mask-repeat: no-repeat;
				mask-position: center;
				mask-size: contain;
				cursor: pointer;
				z-index: 999;
			}

			label {
				height: 100%;
				width: 100%;
				text-align: left;
				align-self: center;
				padding: 20px 0 15px;
			}
		}
	}
}
