// Пока закомител ломает верстку
@use "sass:map";
@import "base";
@import "normalize.css";
@include site-fonts;

// *,
// *::before,
// *::after {
// 	box-sizing: inherit;
// }

// html {
// 	box-sizing: border-box;
// }

// header {
// 	//
// }

// body {
// 	min-width: 320px;
// 	background-color: $body-background;
// 	color: $body-text-color;
// 	font-family: $body-font-family;
// }

// footer {
// 	//
// }

// img {
// 	max-width: 100%;
// }

// h1,
// .c-h1 {
// 	margin: 0;
// 	padding: 0;
// }

// h2,
// .c-h2 {
// 	margin: 0;
// 	padding: 0;
// }

// h3,
// .c-h3 {
// 	margin: 0;
// 	padding: 0;
// }

// p,
// div,
// a {
// 	margin: 0;
// 	padding: 0;
// }

// .l-content-container {
// 	@include content-container;

// 	margin: 0 auto;
// 	width: 100%;
// }

// .l-content-container-left {
// 	@include content-container("left");

// 	width: 100%;
// }

// .l-content-container-right {
// 	@include content-container("right");

// 	width: 100%;
// }

// // Класс для контента из визуального редактора (HTML контент)
// .c-content {
// 	h1,
// 	.c-h1,
// 	h2,
// 	.c-h2,
// 	h3,
// 	.c-h3,
// 	h4,
// 	.c-h4,
// 	p,
// 	ul,
// 	div {
// 		margin-bottom: 30px;

// 		&:last-child {
// 			margin-bottom: 0;
// 		}
// 	}
// }

// iframe {
// 	max-width: 1200px;
// 	width: 100% !important;
// 	height: auto !important;
// 	aspect-ratio: 16/9;
// }

// // Отступы
// @each $name, $indent in $indents {
// 	.margin-#{$name} {
// 		&-top {
// 			@include color-indent($name, true, true);
// 		}

// 		&-bottom {
// 			@include color-indent($name, false, true);
// 		}
// 	}

// 	.padding-#{$name} {
// 		&-top {
// 			@include color-indent($name, true, false);
// 		}

// 		&-bottom {
// 			@include color-indent($name, false, false);
// 		}
// 	}
// }

.title-form {
	margin: 0;
	padding: 0;
	font-size: 16px;
	line-height: 120%;
	font-weight: 700;

	@include breakpoint("tablet") {
		font-size: 18px;
	}

	@include breakpoint("middle") {
		font-size: 20px;
	}

	@include breakpoint("full") {
		font-size: 24px;
	}
}

.button {
	background-color: #686c6e;
	color: #fff;
	text-transform: uppercase;
	font-family: Arial;
	font-size: 14px;
	line-height: 16.8px;
	font-weight: 400;
	padding: 20px 40px;
	cursor: pointer;
	transition: background-color 0.2s linear;
	height: auto;

	&:hover {
		background-color: #0098c3;
	}
}

/* stylelint-disable-next-line selector-class-pattern */
.b-file .filepond--drop-label::after {
	pointer-events: none;
}
