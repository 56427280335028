@import "styles/base";

$block-name: "b-phone";

.#{$block-name} {
	// TODO: нужно убрать font-size
	input {
		transition:
			all 5000s,
			margin 0s,
			padding 0s,
			border-bottom 0s;
		border: 1px solid $color-gray-light;
		background: none;
		outline: none;
		width: 100%;
		line-height: 130%;
		padding: 20px;
		font-size: 13px;
		font-weight: 400;
		color: $color-black;
		box-sizing: border-box;

		@include breakpoint("tablet") {
			font-size: 14px;
		}

		@include breakpoint("middle") {
			font-size: 16px;
		}
	}

	&.error {
		input {
			padding: 30px 20px 10px;
		}

		.#{$block-name} {
			&__error-message {
				display: block;
				z-index: 1;
			}
		}

		.iti__selected-dial-code,
		.iti__arrow,
		.iti__flag {
			margin-top: 15px;
		}
	}

	&__error-message {
		display: none;
		color: $color-red;
		position: absolute;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		left: 20px;
		top: 8px;
	}

	.iti {
		width: 100%;

		--iti-path-flags-1x: url("node_modules/intl-tel-input/build/img/flags.png");
		--iti-path-flags-2x: url("node_modules/intl-tel-input/build/img/flags@2x.png");
		--iti-path-globe-1x: url("node_modules/intl-tel-input/build/img/globe.png");
		--iti-path-globe-2x: url("node_modules/intl-tel-input/build/img/globe@2x.png");
	}

	.iti__selected-country-primary {
		padding-left: 20px;
	}

	.iti__selected-dial-code {
		color: $color-graphite;
		font-family: Arial;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
	}

	.iti__flag-box {
		width: auto;
	}
}
