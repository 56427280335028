@import "styles/base";

$block-name: "b-select";

.#{$block-name} {
	width: 100%;
	position: relative;

	// TODO: нужно убрать font-size
	&__select {
		transition:
			all 5000s,
			margin 0s,
			padding 0s,
			border-bottom 0s;
		border: 1px solid $color-gray-light;
		background: none;
		outline: none;
		width: 100%;
		line-height: 130%;
		padding: 20px;
		font-size: 13px;
		font-weight: 400;
		color: $color-gray-dark;
		box-sizing: border-box;

		@include breakpoint("tablet") {
			font-size: 14px;
		}

		@include breakpoint("middle") {
			font-size: 16px;
		}
	}

	.choices {
		&::after {
			content: "";
			width: 16px;
			height: 8px;
			position: absolute;
			right: 26px;
			background: $color-graphite;
			mask-image: url("img/arrow-select.svg");
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: cover;
			cursor: pointer;
			border: none;
			transition: all 0.4s;
			transform: rotate(180deg);
		}

		&.is-open {
			z-index: 2;

			&::after {
				transform: rotate(0deg);
				margin: 0 !important;
			}
		}
	}

	.choices__inner,
	.choices__list {
		box-sizing: border-box;
		width: 100%;
		padding: 0 !important;
		min-height: auto;
		background: $color-white;
	}

	.choices__list[role="listbox"] {
		max-height: 200px !important;
	}

	.choices__item {
		padding: 20px;
		color: $color-black;
		line-height: 130%;
		font-size: 13px;
		font-weight: 400;
		box-sizing: border-box;

		@include breakpoint("tablet") {
			font-size: 14px;
		}

		@include breakpoint("middle") {
			font-size: 16px;
		}

		&--disabled {
			display: none !important;
		}

		&--choice {
			padding-left: 20px !important;
			padding-top: 15px !important;
			color: $color-uikit-graphite;
			line-height: 130%;
			font-size: 13px;
			font-weight: 400;
			box-sizing: border-box;

			&:last-child {
				padding-bottom: 15px !important;
			}

			&:hover,
			&.is-highlighted {
				color: $color-aquamarine;
				background-color: unset !important;
			}

			@include breakpoint("tablet") {
				font-size: 14px;
			}

			@include breakpoint("middle") {
				font-size: 16px;
			}
		}
	}

	.choices__input {
		padding: 10px 20px !important;
		color: $color-gray-dark;
		line-height: 130%;
		font-size: 13px;
		font-weight: 400;
		box-sizing: border-box;

		@include breakpoint("tablet") {
			font-size: 14px;
		}

		@include breakpoint("middle") {
			font-size: 16px;
		}
	}

	&.error {
		input {
			padding: 30px 20px 10px;
		}

		.#{$block-name} {
			&__error-message {
				display: block;
				z-index: 1;
			}
		}
	}

	&__error-message {
		display: none;
		color: $color-red;
		position: absolute;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		left: 20px;
		top: 8px;
	}
}
