@import "styles/base";

$block-name: "b-checkbox";

.#{$block-name} {
	&.error {
		.#{$block-name} {
			&__checkbox-view {
				border: 1px solid $color-red;
			}

			&__desc {
				color: $color-red;

				a {
					color: $color-red;
					border-bottom: 2px solid $color-red;
				}
			}
		}
	}

	&__checkbox {
		position: absolute;
		appearance: none;
	}

	&__checkbox-label {
		cursor: pointer;
		display: flex;
		font-size: 18px;
		align-items: center;
	}

	&__checkbox-view {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 15px;
		height: 15px;
		margin-right: 10px;
		border: 1px solid $color-graphite;
		flex-shrink: 0;
	}

	&__checkbox-icon {
		opacity: 0;
		transition: 1s;
	}

	&__checkbox:checked + &__checkbox-view {
		border: 1px solid $color-aquamarine;
		background: $color-aquamarine;
		mask-image: url("img/checked.svg");
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;
		cursor: pointer;
	}

	&__checkbox:checked + &__checkbox-view &__checkbox-icon {
		opacity: 1;
	}

	&__desc {
		display: flex;
		color: $color-graphite;
		font-family: Arial;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;

		p {
			margin: 0;
			font-size: 16px !important;
		}

		a {
			text-decoration: none;
			text-transform: none;
			border-bottom: 2px solid $color-graphite;
			color: $color-graphite;
			font-family: Arial;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;

			&:hover {
				border-bottom: 2px solid $color-aquamarine;
			}
		}
	}

	&__checkbox:checked + &__checkbox-view + &__desc {
		a {
			border-bottom: 2px solid $color-aquamarine;

			&:hover {
				border-bottom: 2px solid $color-graphite;
			}
		}
	}
}
