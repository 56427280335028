@import "styles/base";

$block-name: "b-email";

.#{$block-name} {
	width: 100%;
	position: relative;

	// TODO: нужно убрать font-size
	input {
		transition:
			all 5000s,
			margin 0s,
			padding 0s,
			border-bottom 0s;
		border: 1px solid $color-gray-light;
		background: none;
		outline: none;
		width: 100%;
		line-height: 130%;
		padding: 20px;
		font-size: 13px;
		font-weight: 400;
		color: $color-black;
		box-sizing: border-box;

		@include breakpoint("tablet") {
			font-size: 14px;
		}

		@include breakpoint("middle") {
			font-size: 16px;
		}
	}

	&.error {
		input {
			padding: 30px 20px 10px;
		}

		.#{$block-name} {
			&__error-message {
				display: block;
				z-index: 1;
			}
		}
	}

	&__error-message {
		display: none;
		color: $color-red;
		position: absolute;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		left: 20px;
		top: 8px;
	}
}
